import React from 'react'
import { Grid, Typography } from '@mui/material'
import './Clients.scss'
import CustomSlider from '../Slider/CustomSlider'

const Clients = () => {

    return (
        <div id='Clients'>
            <Grid container spacing={2} className='clients-container'>
                <Grid item className='clients-heading-item'>
                    <Typography variant='h4' className='clients-heading'>Our Clients</Typography>
                </Grid>
                <Grid item className='straight-line-item'>
                    <div className='straight-line'></div>
                </Grid>
                <Grid item className='slider-item'>
                    <CustomSlider />
                </Grid>
            </Grid>
        </div>
    )
}

export default Clients