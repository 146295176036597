import React from 'react'
import Navbar from '../../components/navbar/navbar'
import MainContainer from '../../components/MainContainer/MainContainer'
import Clients from '../../components/Clients/Clients'
import AboutUs from '../../components/AboutUs/AboutUs'
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre'
import WhatWeDo from '../../components/WhatWeDo/WhatWeDo'
import Idea from '../../components/Idea/Idea'
import Skills from '../../components/Skills/Skills'
import PricingPlan from '../../components/PricingPlan/PricingPlan'
import FeedBacks from '../../components/FeedBacks/FeedBacks'
import ContactUs from '../../components/ContactUs/ContactUs'
import Location from '../../components/Location/Location'
import Footer from '../../components/Footer/Footer'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import './landing.scss';
import ParticlesComp from '../../components/Particles/Particles'
import Expertise from '../../components/Expertise/Expertise'

const Landing = () => {
  const toTop = () => {
    window.scrollTo(0, 0)
  }
  return (
    <>
      <Navbar />
      {/* <ParticlesComp /> */}
      <MainContainer />
      <AboutUs />
      {/* <WhoWeAre /> */}
      <WhatWeDo />
      <Expertise />
      <Idea />
      <Clients />
      {/* <Skills /> */}
      {/* <PricingPlan /> */}
      {/* <FeedBacks /> */}
      <ContactUs />
      {/* <Location /> */}
      <Footer />
      <div style={{ textAlign: 'right' }}>
        <button onClick={toTop} className='top-button'><KeyboardArrowUpIcon /></button>
      </div>
    </>
  )
}

export default Landing