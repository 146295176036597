import React, { useState, useRef } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import './ContactUs.scss'
import emailjs from '@emailjs/browser';


const ContactUs = () => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [showThankYouPopup, setShowThankYouPupup] = useState(false)
  const form = useRef();
  const onNameChange = (e) => {
    let minLengthOfName = 3
    if (e) {
      if (validateEmpty(e.target.value) === 1) {
        setNameError('Name can not be null!');
        setName(e.target.value)
      }
      else if (validateLength(e.target.value, minLengthOfName) === 1) {
        setNameError(`Name can not be less than ` + minLengthOfName + ' characters!')
        setName(e.target.value)
      }
      else if (validateSpecialCharacters(e.target.value)) {
        setNameError(`Name can't contain '!', ',', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=',  !`)
        setName(e.target.value)
      }
      else if (validateNumbers(e.target.value)) {
        setNameError(`Name can't contain numbers!`)
        setName(e.target.value)
      }
      else {
        setNameError('');
        setName(e.target.value)
      }
    }
  }

  const onPhoneChange = (e) => {
    let phoneLength = 11
    if (e) {
      if (validateEmpty(e.target.value) === 1) {
        setPhoneError('Phone can not be null!');
        setPhone(e.target.value)
      }
      else if (validatePhoneLength(e.target.value, phoneLength) === 1) {
        setPhoneError(`Phone must be ` + phoneLength + ' digits!')
        setPhone(e.target.value)
      }
      else if (validateSpecialCharacters(e.target.value)) {
        setPhoneError(`Phone can't contain '!', ',', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=',  !`)
        setPhone(e.target.value)
      }
      else {
        setPhoneError('');
        setPhone(e.target.value)
      }
    }
  }

  const validateEmpty = (x) => {
    if (!x) {
      return 1
    }
  }

  const validateLength = (value, length) => {
    if (value) {
      if (value.length < length) {
        return 1
      }
    }
  }

  const validatePhoneLength = (value, length) => {
    if (value) {
      if (value.length != length) {
        return 1
      }
    }
  }

  const validateSpecialCharacters = (value, specialCharacters = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '+', '=', ',']) => {
    if (value) {
      if (specialCharacters.some((item) => value.includes(item))) {
        return 1;
      }
    }
  }

  const validateNumbers = (value) => {
    if (value) {
      let specialCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      if (specialCharacters.some((item) => value.includes(item))) {
        return 1;
      }
    }
  }

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  };

  const onEmailChange = (e) => {
    if (e) {
      if (validateEmpty(e.target.value) === 1) {
        setEmailError('Email can not be null!');
        setEmail(e.target.value)
      }
      else if (!validateEmail(e.target.value)) {
        setEmailError('Entered email is invalid!');
        setEmail(e.target.value)
      }
      else {
        setEmailError('');
        setEmail(e.target.value)
      }
    }
  };

  const onMessageChange = (e) => {
    let minMessageLength = 20
    let specialCharacters = ['!', '#', '%', '^', '&', '_']
    if (e) {
      if (validateEmpty(e.target.value) === 1) {
        setMessageError('Message can not be null!');
        setMessage(e.target.value)
      }
      else if (validateLength(e.target.value, minMessageLength) === 1) {
        setMessageError('Message can not be less than 20 characters!');
        setMessage(e.target.value)
      }
      else if (validateSpecialCharacters(e.target.value, specialCharacters)) {
        setMessageError(`Message can't contain '!', '#', '%', '^', '&', '_' !`)
        setMessage(e.target.value)
      }
      else {
        setMessageError('');
        setMessage(e.target.value)
      }
    }
  }

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/; // Only allows letters and spaces

    if (!name || !name.trim()) {
      return "Name is required";
    } else if (!nameRegex.test(name.trim())) {
      return "Invalid characters in name";
    }

    return ""; // Empty string indicates a valid name
  };

  const validateEmailInput = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !email.trim()) {
      return "Email is required";
    } else if (!emailRegex.test(email.trim())) {
      return "Invalid email format";
    }

    return ""; // Empty string indicates a valid email
  };

  const validateMessage = (message) => {
    if (!message || !message.trim()) {
      return "Message is required";
    }

    return ""; // Empty string indicates a valid message
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const isValidName = validateName(name)
    if (isValidName) {
      setNameError(isValidName)
    } else {
      setNameError()
    }
    const isValidEmail = validateEmailInput(email)
    if (isValidEmail) {
      setEmailError(isValidEmail)
    } else {
      setEmailError()
    }
    const isValidMessage = validateMessage(message)
    if (isValidMessage) {
      setMessageError(isValidMessage)
    } else {
      setMessageError()
    }
    if (!isValidName && !isValidEmail && !isValidMessage) {
      emailjs.sendForm('service_bjepq2k', 'template_zo2059i', form.current, 'EUJLyM97-kwE0VaEo')
        .then((result) => {
          setName('')
          setEmail('')
          setPhone('')
          setMessage('')
          setShowThankYouPupup(true);
          // alert("message sent succesfully");
        }, (error) => {
          alert("failed to send message");
          console.log(error)
        });
    } else {
      // alert('Form Errors!')
    }
  };
  return (
    <div id="ContactUs">
      <Grid container className='feedback-container' >
        <Grid item className='left-content'>
          <Typography variant='h4' className='main-heading'>
            Let's Start
          </Typography>
          <div className='bottom-para'>
            <Typography variant="p" className='bottom-para-text'>
              Thank you for your interest in our bespoke software devlopment services.
            </Typography>
            <br />
            <br />
            <Typography variant="p" className='bottom-para-text'>
              Whether you are looking to develop a complete idea, hire dedicated and like-minded individuals or just want to learn more about Codinit, we are connecting ypu with our Business Development Team. They will assit you with queries you may have.
            </Typography>
          </div>
        </Grid>
        <Grid item className="right-content">
          <form className='form' ref={form} onSubmit={sendEmail}>
            <div className="form-row">
              <input
                className='textField'
                type="text"
                placeholder="Name"
                name='from_name'
                id='from_name'
                required
                value={name}
                onChange={onNameChange}
              />
              <input
                className='textField'
                type="number"
                placeholder="Phone"
                name="phone"
                required
                maxLength={11}
                value={phone}
                onChange={onPhoneChange}
              />
            </div>
            <div className="form-row">
              <p className='error'>{nameError}</p>
              <p className='error'>{phoneError}</p>
            </div>
            <div className="form-row">
              <input
                className='textFieldFull'
                type="email"
                placeholder="Email"
                name="email"
                id="email"
                required
                value={email}
                onChange={onEmailChange}
              />
            </div>
            <div className="form-row">
              <p className='error'>{emailError}</p>
            </div>
            <div className="form-row">
              <textarea minRows={15}
                className='textArea' value={message} required name="message" id="message"
                onChange={onMessageChange} placeholder='Enter your requirements!' />
            </div>
            <div className="form-row">
              <p className='error'>{messageError}</p>
            </div>
            {
              error &&
              <div style={{ width: 'auto', padding: '2%', margin: '2%', backgroundColor: 'lightpink', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p style={{ color: 'red', fontWeight: 'bold' }}>
                  {error}
                </p>
                <p style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => setError()}>
                  X
                </p>
              </div>
            }
            <Box className='buttonContainer' >
              <Button variant="outlined" className='buttonBody' type='submit'>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactUs