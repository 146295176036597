import React, { useEffect, useState } from 'react'
import './Topbar.scss'
import { Typography, Grid } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const Topbar = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isVisible = currentScrollPos === 0;

            setPrevScrollPos(currentScrollPos);
            setIsVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    return (
        <Grid container spacing={2} className={`top-bar-container ${isVisible ? 'visible' : 'hidden'}`} >
            <Grid item className='top-bar-left'>
                <Grid container spacing={2} className='left-container'>
                    <Grid item className='top-bar-text'>
                        <Typography variant='p' className='text-item'>Have a question?</Typography>
                    </Grid>
                    <Grid item className='top-bar-text'>
                        <Typography variant='p' className='text-item'> | </Typography>
                    </Grid>
                    <Grid item className='top-bar-text'>
                        <LocalPhoneIcon className='icon' />
                    </Grid>
                    <Grid item className='top-bar-text'>
                        <Typography variant='p' className='text-item'>+92 339 4112114</Typography>
                    </Grid>
                    <Grid item className='top-bar-text'>
                        <Typography variant='p' className='text-item'> | </Typography>
                    </Grid>
                    <Grid item className='top-bar-text'>
                        <EmailIcon className='icon' />
                    </Grid>
                    <Grid item className='top-bar-text'>
                        <Typography variant='p' className='text-item'>hr@codiniti.com</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className='top-bar-right'>
                <Grid container spacing={2} className='right-container'>
                    <Grid item className='top-bar-text'>
                        <Typography variant='p' className='text-item' >Follow us on: </Typography>
                    </Grid>
                    <Grid item className='top-bar-icon'>
                        <Link to='https://www.facebook.com/profile.php?id=100094295640964' className='link' target='_blank'>
                            <FacebookIcon className='icon' />
                        </Link>
                    </Grid>
                    <Grid item className='top-bar-icon'>
                        <Link to='https://twitter.com/codiniti' className='link' target='_blank'>
                            <TwitterIcon className='icon' />
                        </Link>
                    </Grid>
                    <Grid item className='top-bar-icon'>
                        <Link to='https://www.instagram.com/codiniti/' className='link' target='_blank'>
                            <InstagramIcon className='icon' />
                        </Link>
                    </Grid>
                    <Grid item className='top-bar-icon'>
                        <Link to='https://www.linkedin.com/company/codiniti/' className='link' target='_blank'>
                            <LinkedInIcon className='icon' />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Topbar