import React from 'react'
import { Grid, Typography } from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import './Footer.scss'
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import CodinitiLogo from '../../images/CODINITI LOGO.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    return (
        <>
            <Grid container spacing={2} className='footer-container'>
                <Grid item lg={5} className='logo-item'>
                    <Grid container spacing={2} padding={2}>
                        <Grid item lg={12}>
                            <img src={CodinitiLogo} className='logo' style={{ display: 'flex' }} alt='codiniti logo' />
                        </Grid>
                        <Grid item lg={12}>
                            <Typography variant='p' sx={{ color: 'white', paddingBottom: '2rem' }}>
                                Welcome to CODINITI, where a dedicated team of experienced professionals awaits to shape your software development vision into reality.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={2} className='links-item'>
                    <Grid container spacing={2} padding={2}>
                        <Grid item lg={12}>
                            <Typography variant='p' className='follow-us-title'>Follow us:</Typography>
                        </Grid>
                        <Grid item lg={3}>
                            <Link to='https://www.facebook.com/profile.php?id=100094295640964' className='link-text' target='_blank'>
                                <FacebookIcon />
                            </Link>
                        </Grid>
                        <Grid item lg={3}>
                            <Link to='https://twitter.com/codiniti' className='link-text' target='_blank'>
                                <TwitterIcon />
                            </Link>
                        </Grid>
                        <Grid item lg={3}>
                            <Link to='https://www.instagram.com/codiniti/' className='link-text' target='_blank'>
                                <InstagramIcon />
                            </Link>
                        </Grid>
                        <Grid item lg={3}>
                            <Link to='https://www.linkedin.com/company/codiniti/' className='link-text' target='_blank'>
                                <LinkedInIcon />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={5} className='contact-us-item'>
                    <Grid container spacing={2} padding={2}>
                        <Grid item lg={12}>
                            <Typography variant='p' className='follow-us-title'>Head Office:</Typography>
                        </Grid>
                        <Grid item lg={1}>
                            <LocalPhoneSharpIcon />
                        </Grid>
                        <Grid item lg={3}>
                            <Typography variant='p' className='follow-us-title'>+92 339 4112114</Typography>
                        </Grid>
                        <Grid item lg={1}>
                            <BusinessSharpIcon />
                        </Grid>
                        <Grid item lg={7}>
                            <Typography variant='p' className='follow-us-title'>Lower Ground Floor, Building 1, Awan-e-Iqbal, Egerton Road, Gareshow Lahore, Punjab Pakistan 54000.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className='last-bar'>
                <Grid item lg={12} >
                    <Typography variant='p'> Copyright © 2024. All Rights Reserved. Designed and Developed By <span style={{ textDecoration: 'underline' }}>CODEINITY</span> with <span style={{ color: 'white' }}>&#x2764;</span> </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default Footer