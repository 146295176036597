import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Grid, Typography, Paper, Button } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
const OtpVerification = () => {

    let navigate = useNavigate();
    const { state } = useLocation();
    const { otpval, values, role } = state;
    const [userValues, setUserValues] = React.useState();
    const [userRole, setRole] = React.useState();
    const status = '2'


    React.useEffect(() => {
        setUserValues(values);
        setRole(role);
    }, [])

    useEffect(() => {
        console.log(userValues, 'userValues')
        console.log(userRole, 'role')
        console.log(otpval, 'status')
    }, [userRole, userValues])

    const [otp, setOtp] = React.useState()

    const otphandle = (newvalue) => {
        setOtp(newvalue);
    }

    const Otpsubmit = () => {
        if (otp == otpval) {
            var formdata = new FormData();
            formdata.append("userId", values.iv_userid);
            console.log("userId", values.iv_userid);
            formdata.append("name", values.iv_name);
            console.log("name", values.iv_name);
            formdata.append("age", values.iv_age);
            console.log("age", values.iv_age);
            formdata.append("email", values.iv_email);
            console.log("email", values.iv_email);
            formdata.append("phone", values.iv_phone);
            console.log("phone", values.iv_phone);
            formdata.append("password", values.iv_password);
            console.log("password", values.iv_password);
            formdata.append("roleId", role);
            formdata.append("status", status);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            fetch("http://localhost:5000/users", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 200) {
                        toast.success(result.message, { position: toast.POSITION.TOP_RIGHT })
                        navigate('/')
                    } else {
                        toast.error(result.message, { position: toast.POSITION.TOP_RIGHT })
                    }
                })
                .catch(error => console.log('error', error));
        }
        else {
            toast.error("Invalid OTP", { position: toast.POSITION.TOP_RIGHT })
        }
    }
    return (
        <Paper container className='mainLoginContainer' sx={{ backgroundColor: '#031637' }}>
            <Typography item variant='h6' className='formMiniTitle' >WAIT</Typography>
            <Typography item variant='h4' className='formTitle' >VERIFY OTP</Typography>
            <Grid container className='formContainer'>
                <Grid item lg={12}>
                    <MuiOtpInput length={4} name="otp" value={otp} onChange={otphandle} />
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className='button'
                    onClick={Otpsubmit}
                    sx={{
                        mt: 3, mb: 2, backgroundColor: '#3760E7', '&:hover': {
                            backgroundColor: 'black',
                            color: 'white',
                        },
                        height: 40
                    }}
                >
                    Confirm
                </Button>
                <ToastContainer />

            </Grid>
        </Paper>
    )
}

export default OtpVerification