import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import './WhatWeDo.scss';
import ServiceCard from '../ServiceCard/ServiceCard';
import WebMobileColor from '../../images/Assets/4. Services/Web & Mobile Development Icon@4x.png';
import WebMobileWhite from '../../images/Assets/4. Services/Web & Mobile Development Icon White@4x.png';
import TechnologyConsultationColor from '../../images/Assets/4. Services/Technology Consultancy Icon@4x.png';
import TechnologyConsultationWhite from '../../images/Assets/4. Services/Technology Consultancy Icon White@4x.png';
import SoftwareITSupportColor from '../../images/Assets/4. Services/Software & IT Support Icon@4x.png';
import SoftwareITSupportWhite from '../../images/Assets/4. Services/Software & IT Support Icon White@4x.png';
import WebHostingPublishingColor from '../../images/Assets/4. Services/Web Hosting & Publishing Icon@4x.png';
import WebHostingPublishingWhite from '../../images/Assets/4. Services/Web Hosting & Publishing Icon White@4x.png';

const WhatWeDo = () => {

  const services = [
    {
      iconColor: WebMobileColor,
      iconWhite: WebMobileWhite,
      title: 'Web & Mobile Development',
      description: 'Are you looking to build a cutting-edge web or mobile application from scratch or enhance your existing product, we can be your trusted partner for digital solutions in web and mobile app development.',
    },
    {
      iconColor: TechnologyConsultationColor,
      iconWhite: TechnologyConsultationWhite,
      title: 'Technology Consultancy',
      description: 'Are you looking to build a cutting-edge web or mobile application from scratch or enhance your existing product, we can be your trusted partner for digital solutions in web and mobile app development.',
    },
    {
      iconColor: SoftwareITSupportColor,
      iconWhite: SoftwareITSupportWhite,
      title: 'Software & IT Support',
      description: 'Are you looking to build a cutting-edge web or mobile application from scratch or enhance your existing product, we can be your trusted partner for digital solutions in web and mobile app development.',
    },
    {
      iconColor: WebHostingPublishingColor,
      iconWhite: WebHostingPublishingWhite,
      title: 'Web Hosting & Publishing',
      description: 'Are you looking to build a cutting-edge web or mobile application from scratch or enhance your existing product, we can be your trusted partner for digital solutions in web and mobile app development.',
    },

  ]

  return (
    <div id="WhatWeDo">
      <div className='what-we-do-container' >
        <div className='text-container'>
          <h3 className='main-heading'>Services we offer</h3>
        </div>
        <div className='bottom-para'>
          <p className='bottom-para-text'>A comprehensive range of software development solutions to meet your business needs.</p>
        </div>
        <Grid container spacing={2} className='services-container'>
          {services.map((item) => {
            return (
              <Grid item className='services-item'>
                <ServiceCard item={item} />
              </Grid>
            )
          })}
        </Grid>
        <div className='last-para'>
          <p className='last-para-text'>Excellence is not an act, but a habit. At Codinity, we make it our habit to deliver exceptional software services that exceed client expectations and drive tangible business results.</p>
        </div>
      </div >
    </div >
  )
}

export default WhatWeDo