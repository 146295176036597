import React from 'react'
import { Grid, Paper, Typography, TextField, Checkbox, Button } from '@mui/material';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom'
import './signup.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { signupSchema } from '../../schemas/signupSchema';

const SignUp = () => {

    const initialValues = {
        iv_userid: "",
        iv_name: "",
        iv_age: "",
        iv_email: "",
        iv_phone: "",
        iv_password: "",
        iv_confirm_password: "",
        iv_termsAndConditions: false,
    };
    let role = 2
    let status = '1'
    let navigate = useNavigate();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: signupSchema,
            onSubmit: (values, { resetForm }) => {
                var formdata = new FormData();
                formdata.append("userId", values.iv_userid);
                console.log("userId", values.iv_userid);
                formdata.append("name", values.iv_name);
                console.log("name", values.iv_name);
                formdata.append("age", values.iv_age);
                console.log("age", values.iv_age);
                formdata.append("email", values.iv_email);
                console.log("email", values.iv_email);
                formdata.append("phone", values.iv_phone);
                console.log("phone", values.iv_phone);
                formdata.append("password", values.iv_password);
                console.log("password", values.iv_password);
                formdata.append("roleId", role);
                formdata.append("status", status);
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                fetch("http://localhost:5000/users", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === 200) {
                            let otpval = result.OTP
                            navigate('/otp-verification', { state: { otpval, values, role } })
                            toast.success(result.message, { position: toast.POSITION.TOP_RIGHT })
                        } else {
                            toast.error(result.message, { position: toast.POSITION.TOP_RIGHT })
                        }
                    })
                    .catch(error => console.log('error', error));
            },
        });

    return (
        <Paper container className='mainLoginContainer' sx={{ backgroundColor: '#031637' }}>
            <Typography item variant='h6' className='formMiniTitle' >WELLCOME</Typography>
            <Typography item variant='h4' className='formTitle' >TO TAKRA</Typography>
            <Grid container className='formContainer'>
                <Grid item lg={12} sm={12}>
                    <Typography variant='p' className='fieldLabel' >USERID <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        variant="standard"
                        value={values.iv_userid}
                        name="iv_userid"
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Enter unique user id.'
                        className='textField' />
                    {errors.iv_userid && touched.iv_userid ? (
                        <p className="form-error">{errors.iv_userid}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} sm={12}>
                    <Typography variant='p' className='fieldLabel' >NAME <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        variant="standard"
                        value={values.iv_name}
                        name="iv_name"
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Enter your name.'
                        className='textField' />
                    {errors.iv_name && touched.iv_name ? (
                        <p className="form-error">{errors.iv_name}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} sm={12}>
                    <Typography variant='p' className='fieldLabel' >AGE <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        variant="standard"
                        value={values.iv_age}
                        name="iv_age"
                        type='number'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Enter your age.'
                        className='textField' />
                    {errors.iv_age && touched.iv_age ? (
                        <p className="form-error">{errors.iv_age}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} sm={12}>
                    <Typography variant='p' className='fieldLabel' >EMAIL <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        variant="standard"
                        value={values.iv_email}
                        name="iv_email"
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Enter your email.'
                        className='textField' />
                    {errors.iv_email && touched.iv_email ? (
                        <p className="form-error">{errors.iv_email}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} sm={12}>
                    <Typography variant='p' className='fieldLabel' >PHONE <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        variant="standard"
                        value={values.iv_phone}
                        name="iv_phone"
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Enter your phone number.'
                        className='textField' />
                    {errors.iv_phone && touched.iv_phone ? (
                        <p className="form-error">{errors.iv_phone}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} >
                    <Typography variant='p' className='fieldLabel' >PASSWORD <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12} >
                    <TextField
                        variant="standard"
                        value={values.iv_password}
                        name="iv_password"
                        type='password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Enter your password.'
                        className='textField' />
                    {errors.iv_password && touched.iv_password ? (
                        <p className="form-error">{errors.iv_password}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} >
                    <Typography variant='p' className='fieldLabel' >CONFIRM PASSWORD <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12} >
                    <TextField
                        variant="standard"
                        value={values.iv_confirm_password}
                        name="iv_confirm_password"
                        type='password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Re-Enter your password.'
                        className='textField' />
                    {errors.iv_confirm_password && touched.iv_confirm_password ? (
                        <p className="form-error">{errors.iv_confirm_password}</p>
                    ) : null}
                </Grid>
                <Grid item lg={2}>
                    <Checkbox className='checkbox' />
                </Grid>
                <Grid item lg={10} className='rememberme'>
                    <Typography variant='p' >I agree to the <span style={{ color: '#2CD2A9' }}>Terms and conditions</span></Typography>
                </Grid>
                <Grid item lg={12} className='loginButtonBack'>
                    <Button variant='contained' className='loginButton' onClick={handleSubmit}>
                        Sign Up
                    </Button>
                </Grid>
                <Grid item lg={12} className='signUpNow' >
                    <Typography variant='p' className='signUpNowText' >Already have an account?
                        <Link to='/'>
                            <span style={{ color: '#2CD2A9' }}>
                                Sign In
                            </span>
                        </Link>
                    </Typography>
                </Grid>
                <Grid item lg={12} className='signUpNow' >
                    <Grid container className='orContainer' >
                        <Grid item lg={5}>
                            <hr />
                        </Grid>
                        <Grid item lg={2} className='or'>
                            OR
                        </Grid>
                        <Grid item lg={5}>
                            <hr />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12}>
                    <Grid container className='socialMediaIcons' >
                        <Grid item >
                            <FacebookIcon className='icon' />
                        </Grid>
                        <Grid item >
                            <TwitterIcon className='icon' />
                        </Grid>
                        <Grid item >
                            <InstagramIcon className='icon' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer />
        </Paper>
    )
}

export default SignUp