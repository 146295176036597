import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login/login';
import SignUp from './pages/signup/signup';
import Home from './pages/home/home';
import ProtectedRoutes from './ProtectedRoutes';
import OtpVerification from './pages/otpverification/optverification';
import Landing from './pages/landing/landing';

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Landing />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="login" element={<Login />} />
            <Route path="otp-verification" element={<OtpVerification />} />
            {/* <Route path="forgot-password" element={<Forgotpassword />} />
          <Route path="otp-verification" element={<OTPverification />} />
          <Route path="reset-password" element={<Resetpassword />} /> */}
            <Route element={<ProtectedRoutes />}>
              <Route path="home" element={<Home />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
