import React from 'react'
import { Grid, Typography, Box } from '@mui/material';
import './Idea.scss';
import { Link } from 'react-scroll';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const Idea = () => {
  return (
    <div id="Idea">
      <Grid container className='idea-container' >
        <Grid item className='left-content'>
          <div className='main-text'>
            <h3 >Realizing IDEAs</h3>
          </div>
          <div className='description-text'>
            <p>We are more than just a software development startup – we are the catalyst that transforms your ideas into reality. We understand that the spark of innovation is often accompanied by challenges and complexities. That's where we step in, armed with our expertise and passion for turning concepts into tangible solutions. With a collaborative and client-centric approach, we work closely with you to understand your vision, goals, and requirements.</p>
          </div>
          <div className='button-item'>
            <Link
              to={'ContactUs'}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              <button className='contact-us-button'>Launch Your Idea</button>
            </Link>
          </div>

        </Grid>
        <Grid item className='right-content'>
          <Player
            autoplay
            loop
            src="https://assets6.lottiefiles.com/packages/lf20_ii6qdbgz.json"
            className='player'
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </Grid>
      </Grid>
    </div>
  )
}

export default Idea