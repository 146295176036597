import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, Paper, Typography, TextField, Checkbox, Button, InputAdornment } from '@mui/material'
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import './login.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { loginSchema } from '../../schemas/loginSchema';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const Login = () => {
    const initialValues = {
        iv_email_userid: "",
        iv_password: "",
    };

    const Navigate = useNavigate();

    React.useEffect(() => {
        localStorage.clear();
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: loginSchema,
            onSubmit: (values, action) => {
                var formdata = new FormData();

                if (values.iv_email_userid.includes('@') && values.iv_email_userid.includes('.')) {
                    console.log('Calling API throguh email')
                    formdata.append("userId", '');
                    formdata.append("email", values.iv_email_userid);
                    formdata.append("password", values.iv_password);
                }
                else{
                    console.log('Calling API throguh userId')
                    formdata.append("userId", values.iv_email_userid);
                    formdata.append("email", '');
                    formdata.append("password", values.iv_password);
                }

                
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                fetch("http://localhost:5000/signin", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === 200) {
                            window.localStorage.setItem('user-name', JSON.stringify(result.data[0].name))
                            window.localStorage.setItem('user-phone', JSON.stringify(result.data[0].phone))
                            window.localStorage.setItem('user-email', JSON.stringify(result.data[0].email))
                            window.localStorage.setItem('user-age', JSON.stringify(result.data[0].age))
                            window.localStorage.setItem('user-role', JSON.stringify(result.data[0].roleId))
                            window.localStorage.setItem('user-id', JSON.stringify(result.data[0].userId))
                            Navigate('/home');
                        } else {
                            toast.error(result.message, { position: toast.POSITION.TOP_RIGHT })
                        }
                    })
                    .catch(error => console.log('error', error));
            },
        });

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Paper container className='mainLoginContainer' sx={{ backgroundColor: '#031637' }}>
            <Typography item variant='h6' className='formMiniTitle' >HELLO</Typography>
            <Typography item variant='h4' className='formTitle' >WELCOME BACK</Typography>
            <Grid container className='formContainer'>
                <Grid item lg={12} sm={12}>
                    <Typography variant='p' className='fieldLabel' >EMAIL / USERID <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12}>
                    <TextField
                        variant="standard"
                        value={values.iv_email_userid}
                        name="iv_email_userid"
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder='Enter your email or userid.'
                        className='textField' />
                    {errors.iv_email_userid && touched.iv_email_userid ? (
                        <p className="form-error">{errors.iv_email_userid}</p>
                    ) : null}
                </Grid>
                <Grid item lg={12} >
                    <Typography variant='p' className='fieldLabel' >PASSWORD <span style={{ color: 'red' }}>*</span></Typography>
                </Grid>
                <Grid item lg={12} >
                    <TextField
                        variant="standard"
                        value={values.iv_password}
                        name="iv_password"
                        onChange={handleChange}
                        required
                        type={showPassword ? 'text' : 'password'}
                        onBlur={handleBlur}
                        placeholder='Enter your password.'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" className='input-adornments'>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        sx={{ color: '#A4B0BC' }}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        className='textField' />
                    {errors.iv_password && touched.iv_password ? (
                        <p className="form-error">{errors.iv_password}</p>
                    ) : null}
                </Grid>
                <Grid item lg={2}>
                    <Checkbox className='checkbox' />
                </Grid>
                <Grid item lg={7} className='rememberme'>
                    <Typography variant='p' >Remember Me</Typography>
                </Grid>
                <Grid item lg={3} className='forgotpassword'>
                    <Typography variant='p' >Forgot Password?</Typography>
                </Grid>
                <Grid item lg={12} className='loginButtonBack'>
                    <Button variant='contained' className='loginButton' onClick={handleSubmit}>
                        Log in
                    </Button>
                </Grid>
                <Grid item lg={12} className='signUpNow' >
                    <Typography variant='p' className='signUpNowText' >Don't have an account?
                        <Link to='sign-up' sx={{ textDecoration: 'none' }}>
                            <span style={{ color: '#2CD2A9' }}> Sign Up Now</span>
                        </Link>
                    </Typography>
                </Grid>
                <Grid item lg={12} className='signUpNow' >
                    <Grid container className='orContainer' >
                        <Grid item lg={5}>
                            <hr />
                        </Grid>
                        <Grid item lg={2} className='or'>
                            OR
                        </Grid>
                        <Grid item lg={5}>
                            <hr />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12}>
                    <Grid container className='socialMediaIcons' >
                        <Grid item >
                            <FacebookIcon className='icon' />
                        </Grid>
                        <Grid item >
                            <TwitterIcon className='icon' />
                        </Grid>
                        <Grid item >
                            <InstagramIcon className='icon' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer />
        </Paper>
    )
}

export default Login