import * as Yup from 'yup';

export const signupSchema = Yup.object({
    iv_userid: Yup.string().required("UserId is compulsory!").min(5, "UserId should be minimum 5 characters.").max(30, "UserId should be maximum 30 characters.").matches(/^[^@.]+$/, "Field cannot contain '@' or '.'"),
    iv_name: Yup.string().required("Name is compulsory!").matches(/^[a-zA-Z ]+$/, 'Name can only contain letters and spaces'),
    iv_age: Yup.number().required("Age is compulsory!").min(1, 'Age can not be equals to or less than 0!').max(100, 'Age can not be greater than 100!'),
    iv_email: Yup.string().email("Email must include '@' and '.' in it!").required("Email is compulsory!"),
    iv_phone: Yup.string().required("Phone is compulsory!").min(11,'Enter 11 digit phone number!').max(11,'Enter 11 digit phone number!'),
    iv_password: Yup.string().required("Password is required!").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
        'Password must contain minimum 8 characters including at least 1 capital letter, 1 small letter, 1 number, 1 special character!'),
    iv_confirm_password: Yup.string().oneOf([Yup.ref('iv_password'), null], 'Passwords must match!').required('Confirm Password is required!'),
    //iv_termsAndConditions: Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions').required('You must accept the Terms and Conditions')
});