import { Grid, Typography } from '@mui/material'
import { Link } from 'react-scroll'
import React from 'react'
import './MainContainer.scss'
import Typewriter from 'typewriter-effect';

const MainContainer = () => {
    return (
        <div id="Home">
            <Grid className='main-container' >
                <Grid className='text-container'>
                    <Typography variant='h3' className='main-heading'>We 
                        <Typewriter
                            options={{
                                strings: ['Materialize Ideas', 'Automate Processes', 'Make Better Softwares'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </Typography>
                    <Typography variant='p' className='bottom-para'>A team that delivers</Typography>
                </Grid>
                <Grid className='button-container'>
                    <Link
                        to={'Idea'}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}>
                        <button className='get-started-button'>Let's Talk</button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}

export default MainContainer