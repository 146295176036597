import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import './AboutUs.scss'
import GlobalOfficesIcon from '../../images/Assets/3. About Us/Global Office Icon@4x.png';
import TeamMembersIcon from '../../images/Assets/3. About Us/Team Members Icon@4x.png';
import ProjectsIcon from '../../images/Assets/3. About Us/Project Completed Icon@4x.png';

const AboutUs = () => {
  return (
    <div id="AboutUs">
      <Grid container className='about-us-container' >
        <Grid item className='text-container'>
          <Typography variant='h3' className='main-heading'>Launching Journey from our <span style={{ color: '#00a99d' }}>Homeland </span></Typography>
        </Grid>
        <Grid item className='bottom-para'>
          <Typography variant='p' className='bottom-para-text'>Welcome to <span style={{fontWeight:'bold', fontStyle:'italic'}}>Codiniti</span>, a cutting-edge software development startup dedicated to transforming ideas into reality. With a passion for innovation and a commitment to excellence, we specialize in crafting high-quality software solutions that drive business growth and enhance user experiences.</Typography>
        </Grid>
        <Grid item className='card-container'>
          <Grid container className='card-items-container'>
            <Grid item className='card-item'>
              <img src={GlobalOfficesIcon} alt='office icon' className='card-icon' />
              <Typography variant='h2' className='card-figure'>1</Typography>
              <Typography variant='p' className='card-description'>Global offices</Typography>
            </Grid>
            <Grid item className='straight-line-item'>
              <div className='straight-line'></div>
            </Grid>
            <Grid item className='card-item'>
              <img src={TeamMembersIcon} alt='office icon' className='card-icon' />
              <Typography variant='h2' className='card-figure'>4</Typography>
              <Typography variant='p' className='card-description'>Team Members</Typography>
            </Grid>
            <Grid item className='straight-line-item'>
              <div className='straight-line'></div>
            </Grid>
            <Grid item className='card-item'>
              <img src={ProjectsIcon} alt='office icon' className='card-icon' />
              <Typography variant='h2' className='card-figure'>0</Typography>
              <Typography variant='p' className='card-description'>Projects</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default AboutUs