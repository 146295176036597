import React from 'react'
import Slider from "react-slick";
import client1 from '../../images/Assets/2. Clients/Client 1.png'
import client2 from '../../images/Assets/2. Clients/Client 2.png'
import client3 from '../../images/Assets/2. Clients/Client 3.png'
import client4 from '../../images/Assets/2. Clients/Client 4.png'
import client5 from '../../images/Assets/2. Clients/Client 5.png'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './CustomSlider.scss'

const CustomSlider = () => {

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        cssEase: "linear"
      };

    return (
        <Slider {...settings}>
            <div>
                <img src={client1} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client2} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client3} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client4} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client5} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client1} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client2} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client3} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client4} className='client-logo' alt='client1 logo' />
            </div>
            <div>
                <img src={client5} className='client-logo' alt='client1 logo' />
            </div>
        </Slider>
    )
}

export default CustomSlider