import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import './Expertise.scss';
import Minus from '../../images/Assets/5. Experties/Minus Icon@4x.png'
import Plus from '../../images/Assets/5. Experties/Plus Icon@4x.png'
import Net from '../../images/Assets/5. Experties/-net Icon@4x.png'
import Flask from '../../images/Assets/5. Experties/Flask Icon@4x.png'
import Laravel from '../../images/Assets/5. Experties/Laravel Icon@4x.png'
import Numpy from '../../images/Assets/5. Experties/Numpy.jpg'
import Pandas from '../../images/Assets/5. Experties/Pandas.png'
import TensorFlow from '../../images/Assets/5. Experties/TensorFlow.png'
import MongoDb from '../../images/Assets/5. Experties/MongoDb.png'
import MySql from '../../images/Assets/5. Experties/Mysql.png'
import Oracle from '../../images/Assets/5. Experties/Oracle.png'
import Postgres from '../../images/Assets/5. Experties/Postgresql.png'
import Sql from '../../images/Assets/5. Experties/SQL Server.jpg'
import Mui from '../../images/Assets/5. Experties/1_5J2noO7t-nMhJNmqvq4wYg.png'
import BootStrap from '../../images/Assets/5. Experties/Bootstrap.png'
import Reacts from '../../images/Assets/5. Experties/React.png'
import Sass from '../../images/Assets/5. Experties/Sass.png'
import ReactNative from '../../images/Assets/5. Experties/React Native.png'
import Postman from '../../images/Assets/5. Experties/Postman.png'
import Selinium from '../../images/Assets/5. Experties/Selinium.jpg'
import AdobeIllustrator from '../../images/Assets/5. Experties/Adobe Illustrator.png'
import AdobdeXD from '../../images/Assets/5. Experties/Adobe Xd.png'
import Figma from '../../images/Assets/5. Experties/Figma.png'
import Mockplus from '../../images/Assets/5. Experties/Mockplus.jpg'
import Photoshop from '../../images/Assets/5. Experties/Photoshop.png'


const Expertise = () => {

    const [buttons, setButtons] = useState([{
        text: 'AI & Machine Learning',
        open: true,
        images: [Numpy, Pandas, TensorFlow]
    },
    {
        text: 'Back-end Developer',
        open: false,
        images: [Net, Flask, Laravel]
    },
    {
        text: 'Business Intelligence',
        open: false,
        images: [Oracle, Sql]

    },
    {
        text: 'Databases',
        open: false,
        images: [MySql, Postgres, Oracle, MongoDb]

    },
    {
        text: 'Data Science',
        open: false,
        images: [Numpy, Pandas]

    },
    {
        text: 'Front-end Development',
        open: false,
        images: [Mui, BootStrap, Reacts, Sass]

    },
    {
        text: 'Mobile App Development',
        open: false,
        images: [ReactNative]

    },
    {
        text: 'Software Testing',
        open: false,
        images: [Postman, Selinium]

    },
    {
        text: 'UI/UX Designing',
        open: false,
        images: [AdobeIllustrator, AdobdeXD, Figma, Mockplus, Photoshop]

    }]
    )

    const buttonClickFunction = (index) => {
        const updatedArray = buttons.map((item, inx) => {
            if (inx === index) {
                return {
                    ...item,
                    open: true,
                };
            } else {
                return {
                    ...item,
                    open: false,
                };
            }
        });

        setButtons(updatedArray);
    };

    return (
        <div id="WhoWeAre">
            <Grid container className='experitse-container'>
                <Grid item className='text-container'>
                    <Typography variant='h3' className='main-heading'>Expertise we have</Typography>
                </Grid>
                <Grid item className='bottom-para'>
                    <Typography variant='p' className='bottom-para-text'>Our technology expertise spans a wide sepctrum, empowering us to leverage the latest tools and frameworks to create robust and innovative software solutions.</Typography>
                </Grid>
                <Grid item className='icons-item'>
                    <div className='icons-container'>
                        <div className='buttons-container'>
                            {
                                buttons.map((item, index) => {
                                    return (
                                        <div className='buttons-item' key={index}>
                                            <Box onClick={() => buttonClickFunction(index)} className='button'>
                                                {item.open === false ?
                                                    <img src={Plus} alt={item.text} className='button-icons' />
                                                    :
                                                    <>
                                                        <img src={Minus} alt={item.text} className='button-icons' />
                                                    </>
                                                }
                                            </Box>
                                            <Typography className="button-text" sx={{
                                                fontWeight: item.open === false ? '' : '600'
                                            }}>
                                                {item.text}
                                            </Typography>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Divider orientation="vertical" variant="middle" flexItem sx={{ borderWidth: 2 }} />

                        {
                            buttons.map((item, index) => {
                                if (item.open === true) {
                                    return (
                                        <Box className='icon-box'>
                                            {
                                                buttons[index].images.map(img => {
                                                    return (
                                                        <img src={img} alt={item.text} className='icon' />
                                                    )
                                                })
                                            }
                                        </Box>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default Expertise